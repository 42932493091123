let variables: { [key: string]: any } = {}
let isLoaded = false
let resolveLoaded: (() => void) | null = null

export const setVariables = (newVariables: { [key: string]: any }) => {
  variables = newVariables
  isLoaded = true
  if (resolveLoaded) {
    resolveLoaded()
  }
}

export const getVariable = (key: string) => {
  if (!isLoaded) {
    throw new Error('Variables are not loaded yet')
  }
  return variables[key]
}

export const waitForVariables = () => {
  if (isLoaded) {
    return Promise.resolve()
  } else {
    return new Promise<void>((resolve) => {
      resolveLoaded = resolve
    })
  }
}
