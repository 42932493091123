import React, { FC, memo } from 'react'
import cn from 'classnames'
import { useRippling } from 'shared/hooks/UseRippling'
import { IButtonProps } from '../types'
import styles from './Button.module.scss'

export enum ButtonTheme {
  CLEAR = 'clear',
  DEFAULT = 'default',
  OUTLINE = 'outline',
}

export enum ButtonColor {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  GREEN = 'green',
  RED = 'red',
  YELLOW = 'yellow',
  GREY = 'grey',
  PRIMARY_INVERTED = 'primary_inverted',
  RED_REVERTED = 'red_reverted',
  YELLOW_REVERTED = 'yellow_reverted',
  GREEN_REVERTED = 'green_reverted',
  WHITE = 'white',
}

export enum ButtonSize {
  BIG = 'big',
  SMALL = 'small',
  MEDIUM = 'medium',
}

interface RippleCoordinates {
  x: number
  y: number
}

const Button: FC<IButtonProps> = memo(
  ({
    children,
    color = ButtonColor.PRIMARY,
    size = ButtonSize.BIG,
    theme = ButtonTheme.DEFAULT,
    onClick,
    /*
     for correct work custom styles below comment like padding & color & background must be with important flag
     please don't repeat existing styles
     usually you need to change only the padding
     in other cases better to write another style for component
     */
    className,
    ...props
  }) => {
    const { x, y, handleRippleOnClick, isRippling } = useRippling()

    return (
      <button
        onMouseDown={handleRippleOnClick}
        type="button"
        className={cn(styles.btn, styles[theme], styles[color], styles[size], className)}
        onClick={onClick}
        {...props}
      >
        {children}
        {isRippling && (
          <div className={styles['btn-ripple-container']}>
            <span
              className={styles['btn-ripple']}
              style={{
                left: x,
                top: y,
              }}
            />
          </div>
        )}
      </button>
    )
  }
)

export default Button
