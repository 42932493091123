import React, { useState } from 'react'

export const useRippling = () => {
  // Have state of X and Y coordinates. When not rippling, the coords = -1
  const [{ x, y }, setCoordinates] = useState({ x: -1, y: -1 })

  // Set isRippling to true when coordinates are set
  const isRippling = x !== -1 && y !== -1

  // On click, set coordinates to location of click
  const handleRippleOnClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    const { left, top } = e.currentTarget.getBoundingClientRect()
    setCoordinates({
      x: e.clientX - left,
      y: e.clientY - top,
    })

    // Wait for ripple to finish then set coordinates back to -1
    // This will make isRippling to false
    setTimeout(() => {
      setCoordinates({ x: -1, y: -1 })
    }, 600)
  }

  return {
    x,
    y,
    handleRippleOnClick,
    isRippling,
  }
}
