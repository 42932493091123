import { createRoot } from 'react-dom/client'

import React from 'react'
import { BrowserRouter } from 'react-router-dom'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import ReactGA from 'react-ga4'
import { ThemeProvider, ErrorBoundary } from 'app/providers'

export const renderApp = async () => {
  const module = await import('app/App')
  const App = module.default

  ReactGA.initialize('G-S4VQXEHSED')

  const root = createRoot(document.getElementById('root')!)

  root.render(
    <BrowserRouter>
      <ErrorBoundary>
        <DndProvider backend={HTML5Backend}>
          <ThemeProvider>
            <App />
          </ThemeProvider>
        </DndProvider>
      </ErrorBoundary>
    </BrowserRouter>
  )
}
