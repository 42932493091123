import { createRoot } from 'react-dom/client'
import React from 'react'
import axios from 'axios'
import { toast } from 'react-toastify'
import { CircularProgress } from '@mui/material'
import { setVariables, waitForVariables } from 'shared/constants/globalVariables'
import './app/css/flags.css'
import 'react-date-range/dist/styles.css' // main style file
import 'react-date-range/dist/theme/default.css' // theme css file
import 'react-toastify/dist/ReactToastify.css'
import 'react-grid-layout/css/styles.css'
import 'react-resizable/css/styles.css'

import './app/css/index.scss'
import { renderApp } from './renderApp'

const showLoadingIndicator = () => {
  const Theme = localStorage.getItem('theme')

  const color = Theme === 'app_light' ? '#528af0' : '#1066c8'
  const background = Theme === 'app_light' ? '#f7f7f7' : '#161616'

  const root = createRoot(document.getElementById('root')!)
  root.render(
    <div
      style={{
        width: '100vw',
        height: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        gap: 20,
        background,
      }}
    >
      <h3
        style={{
          fontFamily: '"Nunito", sans-serif',
          fontSize: '1.6vw',
          margin: 0,
          padding: 0,
          color,
          fontWeight: 500,
        }}
      >
        Please Wait...
      </h3>
      <CircularProgress
        style={{
          color,
          width: '1.7vw',
          height: ' 1.7vw',
        }}
        thickness={3}
      />
    </div>
  )
}

if (process.env.NODE_ENV === 'development') {
  renderApp()
} else {
  showLoadingIndicator()
  axios
    .get('/config.txt')
    .then((response) => {
      const data = parseVariables(response.data)
      setVariables(data)
      return waitForVariables()
    })
    .then(() => {
      renderApp()
    })
    .catch(() => {
      toast.error('Server error. We already working on it.')
      renderApp()
    })
}

const parseVariables = (data: string): { [key: string]: any } => {
  const lines = data.split('\n')
  const parsedVariables: { [key: string]: any } = {}

  lines.forEach((line) => {
    const [key, value] = line.split('=')
    if (key && value) {
      parsedVariables[key.trim()] = value.trim()
    }
  })

  return parsedVariables
}
