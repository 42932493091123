import React from 'react'
import { useNavigate } from 'react-router-dom'
import Button from '../../../shared/components/ui/Buttons/Button'
import styles from './PageError.module.scss'

const PageError = () => {
  const navigate = useNavigate()

  const reloadPage = () => {
    window.location.reload()
  }

  const goToMainPage = () => {
    navigate('/')
    window.location.reload()
  }

  return (
    <div className={styles.PageError}>
      <span className={styles.smile}>:(</span>
      <h3 className={styles.errorHead}>Something Went Wrong.</h3>
      <h4 className={styles.errorSubHead}>Please refresh the page or contact administrator</h4>
      <div className={styles.buttonsBlock}>
        <Button onClick={reloadPage}>Refresh</Button>
        <Button onClick={goToMainPage}>Go to main page</Button>
      </div>
    </div>
  )
}

export default PageError
